<!--
 * @Author: xiexp
 * @Description: 
 * @Date: 2022-09-05 09:12:01
 * @LastEditTime: 2022-10-11 12:24:54
 * @FilePath: \indiba-web-new\src\pages\pc\china_new.vue
-->
<template>
  <div class="china-container">
    <full-page ref="fullpage" :options="options">
      <!-- 第一屏 -->
      <div class="section bg">
        <div class="nav">
          <div class="nav-logo">
            <img src="../../assets/home/logo.png" alt="logo" />
          </div>
          <div class="nav-menu">
            <ul>
              <li>
                <router-link to="/home#page3">关于INDIBA</router-link>
              </li>
              <li>
                <router-link to="/home#page4">产品方案</router-link>
              </li>
              <li>
                <a target="_blank" >INDIBA资讯</a>
              </li>
              <li>
                <router-link to="/found">找到INDIBA</router-link>
              </li>
              <li>
                <router-link to="/china">INDIBA中国</router-link>
              </li>
              <li>
                <router-link to="/hall">名人堂</router-link>
              </li>
            </ul>
          </div>
          <!-- <div class="nav-tel">
            <a href="tel://010 85951863">
              <i style="margin-right: 8px"></i>
              010 85951863
            </a>
          </div> -->
          <div class="concact_tel">
            <a href="tel://010 85951863">
              <i class="ri-phone-line" style="margin-right: 8px"></i>
              010 85951863
            </a>
          </div>
        </div>
        <div class="china-section1-content">
          <div class="china-section1-content--left">
            <img src="../../assets/china/china-logo.png" alt="indiba中国" />
          </div>
          <div class="china-section1-content--right">
            <img src="../../assets/china/map.png" alt="地图" />
          </div>
        </div>
      </div>
      <!-- 第二屏 -->
      <div class="section section2-address">
        <div class="china-section2-content">
          <div class="china-section2--top">
            <div
              class="section2-item"
              v-for="(item, index) in address_footer"
              :key="item.en_title"
            >
              <img
                class="section2-item-img"
                :src="require(`../../assets/china/0${index + 1}.png`)"
              />
              <div class="section2-item--text">
                <div class="section2-item--title">{{ item.city }}</div>
                <div class="section2-item--desc">{{ item.address1 }}</div>
                <div :key="item.address2" class="section2-item--desc">
                  {{ item.address2 }}
                </div>
              </div>
            </div>
          </div>
          <div class="china-section2--mid">
            <div class="section2-left">
              <p>
                2008年新加坡INDIBA Asia亚洲分公司成立，
                伴随人们美丽与健康意识的增长，INDIBA以其出色的效果、卓越的体验，在韩国、日本、中国香港、中国台湾地区受到了消费者与社会各界的一致褒奖。
              </p>
              <p>
                同时，越来越多的中国消费者了解到INDIBA产品科技，为了更好的服务中国市场，2016年3月，英特波（北京）科技有限公司正式注册成立。
              </p>
              <p>
                七年间，INDIBA陆续于深圳、上海、成都设立办事处，累计销售设备超过2000台、帮助1500家健康美容机构为其客户提供INDIBA设备带来的卓越服务、培训和认证INDIBA操作师5862（持续增长中）人、每年，超过12000+名中国消费者因为INDIBA的突破性的技术与专业服务恢复自然健康美丽。
              </p>
              <p>
                为了更好的保护中国消费者的切身权利，INDIBA（英特波）中国积极探索防伪以及产品溯源系统，致力保护INDIBA合作伙伴利益及消费者权益，共同抵御假冒产品造成消费者权益受损。
              </p>
              <p>
                未来，INDIBA（英特波）将继续以科技之力，深耕中国消费者服务，成为更多更好价值的提供者。
              </p>
            </div>
            <div class="section2-right">
              <img src="../../assets/china/china-section2-right.png" alt="" />
            </div>
            <div class="section2-right-fixed"></div>
          </div>
        </div>
      </div>
      <!-- 第三屏 -->
      <div class="section section3-staffImg">
        <div class="china-section3-content">
          <div class="section3-left-fixed"></div>
          <!-- logo -->
          <div class="section3-left--top">
            <img
              style="max-height: 120px"
              src="../../assets/china/introduce.png"
              alt=""
            />
          </div>
          <!-- 图片轮播 -->
          <div class="demobox">
            <superslide
              :options="options"
              class="picMarquee-left"
              v-if="hackReset"
            >
              <div class="bd section3-right--item">
                <ul class="picList">
                  <li v-for="(item, index) in chinaStaff" :key="index">
                    <!-- <div class="pic">
                      <a href="javascript:;"
                        ><img
                          src="http://www.superslide2.com/demo/images/pic1.jpg"
                      /></a>
                    </div>
                    <div class="title"><a href="javascript:;">效果图1</a></div> -->
                    <div class="header-top">
                      <div class="img-name">{{ item.name }}</div>
                      <div class="img-enName">{{ item.enName }}</div>
                    </div>
                    <img
                      :src="
                        require(`../../assets/home/chinaperson/${item.imgId}.jpg`)
                      "
                      alt=""
                    />
                    <div class="img-bottom">
                      <div class="img-contry">{{ item.attributeproperty }}</div>
                      <div class="img-address">{{ item.compony }}</div>
                    </div>
                  </li>
                  <!-- <li>
                    <div class="pic">
                      <a href="javascript:;"
                        ><img
                          src="http://www.superslide2.com/demo/images/pic2.jpg"
                      /></a>
                    </div>
                    <div class="title"><a href="javascript:;">效果图2</a></div>
                  </li>
                  <li>
                    <div class="pic">
                      <a href="javascript:;"
                        ><img
                          src="http://www.superslide2.com/demo/images/pic3.jpg"
                      /></a>
                    </div>
                    <div class="title"><a href="javascript:;">效果图3</a></div>
                  </li>
                  <li>
                    <div class="pic">
                      <a href="javascript:;"
                        ><img
                          src="http://www.superslide2.com/demo/images/pic4.jpg"
                      /></a>
                    </div>
                    <div class="title"><a href="javascript:;">效果图4</a></div>
                  </li>
                  <li>
                    <div class="pic">
                      <a href="javascript:;"
                        ><img
                          src="http://www.superslide2.com/demo/images/pic5.jpg"
                      /></a>
                    </div>
                    <div class="title"><a href="javascript:;">效果图5</a></div>
                  </li>
                  <li>
                    <div class="pic">
                      <a href="javascript:;"
                        ><img
                          src="http://www.superslide2.com/demo/images/pic6.jpg"
                      /></a>
                    </div>
                    <div class="title"><a href="javascript:;">效果图6</a></div>
                  </li> -->
                </ul>
              </div>

              <div class="hd" slot="titCell"></div>
              <a class="prev" href="javascript:void(0)" slot="prev">
                <img src="../../assets/china/arrow_new.png" alt="上一页" />
              </a>
              <a class="next" href="javascript:void(0)" slot="next">
                <img src="../../assets/china/arrow_new.png" alt="下一页" />
              </a>
            </superslide>
          </div>
          <!-- <div class="section3-left">
            <div class="section3-left--top">
              <img src="../../assets/china/introduce.png" alt="" />
            </div>
            <div class="section3-left--bottom">
              <div class="section3-left--swiperBtn">
                <div class="img-pre">
                  <img
                    @click="handlePre"
                    src="../../assets/china/arrow.png"
                    alt="前一组"
                  />
                </div>
                <div class="img-next">
                  <img
                    class="img-next"
                    @click="handleNext"
                    src="../../assets/china/arrow.png"
                    alt="后一组"
                  />
                </div>
              </div>
              <div class="section3-left--index">
                <a-divider style="height: 2px; background-color: #062870" />
                <div class="img-number">{{`0${imgIndex+1}`}}</div>
              </div>
            </div>
          </div> -->
          <div class="section3-right">
            <!-- <div
              class="section3-right--item"
              v-for="(item, index) in group(chinaStaff, 3)[imgIndex]"
              :key="index"
            >
              <div class="header-top">
                <div class="img-name">{{ item.name }}</div>
                <div class="img-enName">{{ item.enName }}</div>
              </div>
              <img
                :src="
                  require(`../../assets/home/chinaperson/${item.imgId}.jpg`)
                "
                alt=""
              />
              <div class="img-bottom">
                <div class="img-contry">{{ item.attributeproperty }}</div>
                <div class="img-address">{{ item.compony }}</div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </full-page>
  </div>
</template>

<script>
import { address_footer, chinaStaff } from "../../const/index";
export default {
  name: "app",
  computed: {},
  data() {
    return {
      address_footer,
      chinaStaff,
      imgIndex: 0,
      timer: null,
      // hackReset: true,
      // effect: "left",
      // vis: 3,
      // interTime: 50,
      // opp: false,
      // pnLoop: true,
      // trigger: "click",
      // mouseOverStop: true,
      // easing: 'swing',
      // options: {
      //   titCell: ".hd ul", mainCell: ".bd ul",
      //   autoPlay: true,
      //   effect: "left",
      //   vis: 5,
      //   interTime: 50,
      //   trigger: "click",
      // },
      hackReset: true,
      effect: "left",
      autoPlay: true,
      scroll: 1,
      vis: 5,
      trigger: "mouseover",
      easing: "swing",
      delayTime: 500,
      mouseOverStop: true,
      pnLoop: true,
      isAdditional: false,
      options: {
        titCell: ".hd ul",
        mainCell: ".bd ul",
        autoPage: true,
        effect: "left",
        autoPlay: true,
        vis: 5,
      },
    };
  },
  created() {
    document.title = "INDIBA中国";
  },
  mounted() {
    console.log("chinaStaff",chinaStaff);
    this.timer = setInterval(this.swiperImgs, 2000);
  },
  methods: {
    group(array, subGroupLength) {
      var index = 0;
      var newArray = [];

      while (index < array.length) {
        newArray.push(array.slice(index, (index += subGroupLength)));
      }
      return newArray;
    },
    // handleNext() {
    //   if (
    //     this.chinaStaff.length - (this.imgIndex + 1) * 3 < 0 ||
    //     this.chinaStaff.length - (this.imgIndex + 1) * 3 === 0
    //   )   return; // 不足一行
    //   // 清楚掉定时器
    //   clearInterval(this.timer);
    //   this.timer = setInterval(this.swiperImgs, 2000);
    //   this.imgIndex += 1;
    // },
    // handlePre() {
    //   if (this.imgIndex === 0) return;
    //   clearInterval(this.timer);
    //   this.timer = setInterval(this.swiperImgs, 2200);
    //   this.imgIndex -= 1;
    // },
    // 定时器自动播放
    // swiperImgs(){
    //   if(this.chinaStaff.length - (this.imgIndex + 1) * 3 === 0 ){
    //     this.imgIndex = 0;
    //     return;
    //   }
    //   this.imgIndex +=1;
    // }
  },
};
</script>

<style lang="less" less="scoped">
.china-container {
  .bg {
    background: url("../../assets/china/bg.jpg");
    background-size: cover;
    .fp-tableCell {
      background-color: RGBA(21, 63, 185, 0.8);
    }
    .nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      /* margin-top: 32px; */
      height: 48px;
      line-height: 48px;
      padding: 48px;
      .nav-menu {
        min-width: 600px;
        ul {
          display: flex;
          justify-content: space-between;
          li a {
            color: #fff;
          }
        }
      }
      .concact_tel {
        margin-bottom: 1em;
        a {
          display: flex;
          align-items: center;
          color: #fff;
        }
      }
      // .nav-tel {
      //   margin-bottom: 1em;
      //   a {
      //     color: #fff;
      //   }
      // }
    }
    .china-section1-content {
      display: flex;
      justify-content: space-around;
      .china-section1-content--left {
        display: flex;
        flex: 0.75;
        align-items: center;
        justify-content: center;
        margin-top: -168px;
        img {
          max-height: 191px;
          object-fit: contain;
          max-width: 300px;
        }
      }
      .china-section1-content--right {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 138px;
        img {
          min-width: 500px;
          max-width: 830px;
          /* max-width: 746px; */
          object-fit: contain;
        }
      }
    }
  }
  .section2-address {
    .fp-tableCell {
      display: flex;
      justify-content: center;
      align-items: self-start;
    }
    .china-section2-content {
      display: flex;
      justify-content: space-between;
      padding: 68px 6px;
      flex-direction: column;
      max-width: 1200px;
      .china-section2--top {
        display: flex;
        justify-content: space-between;
        padding: 0 32px;
        .section2-item {
          flex: 1;
          margin: 0 16px;
          display: flex;
          .section2-item-img {
            margin-right: 3px;
            width: 48px;
            height: 48px;
            margin-top: 22px;
          }
          .section2-item--text {
            .section2-item--title {
              font-size: 17px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #062870;
            }
            .section2-item--desc {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #5a6771;
            }
          }
        }
      }
      .china-section2--mid {
        padding: 68px 108px;
        display: flex;
        margin-top: 48px;
        .section2-left {
          margin-right: 32px;
        }
        .section2-right-fixed {
          position: absolute;
          right: 0;
          width: 24px;
          height: 380px;
          background: #062870;
        }
      }
    }
  }
  .section3-staffImg {
    display: flex;
    justify-content: center;
    align-items: center;
    .china-section3-content {
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      align-items: center;
      height: 100vh;
      max-width: 1200px;
      .section3-left-fixed {
        width: 24px;
        height: 380px;
        background: #062870;
        left: 0;
        position: absolute;
      }
      .section3-left {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 0 48px;
        justify-content: space-between;
        height: 380px;
        .section3-left--top {
          img {
            max-width: 120px;
          }
        }
        .section3-left--bottom {
          display: flex;
          justify-content: space-between;
          .section3-left--swiperBtn {
            display: flex;
            width: 96px;
            .img-pre,
            .img-next {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 48px;
              height: 48px;
              background-color: #08399f;
              img {
                width: 28px;
                height: 28px;
              }
            }
            .img-pre {
              margin-right: 2px;
            }
            .img-next {
              img {
                transform: rotate(180deg);
              }
            }
            .img-next:hover {
              background-color: #0a1f7e;
            }
            .img-pre:hover {
              background-color: #0a1f7e;
            }
          }
          .section3-left--index {
            display: flex;
            align-items: center;
            flex: 1;
            margin: 0 100px;
            .img-number {
              margin-left: 16px;
              font-size: 24px;
              font-family: Arial;
              font-weight: bold;
              color: #0a1f7e;
            }
          }
        }
      }
      .section3-right {
        display: flex;
        padding: 0 16px;
      }
    }
  }
  .section3-right--item {
    background: linear-gradient(90deg, #08399f, #0a1f7e);
    color: #fff;
    // margin-right: 16px;
    height: 100%;
    .header-top {
      text-align: center;
      padding: 6px;
    }
    .img-bottom {
      text-align: center;
      padding: 8px;
      //   .img-address {
      //     text-align: left;
      //   }
    }
    .img-name {
      font-size: 23px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
    }
    img {
      width: 232px;
      object-fit: contain;
    }
  }
  /* 本例子css */
  .picMarquee-left {
    overflow: hidden;
    position: relative;
    border: 1px solid #ccc;
  }
  .picMarquee-left .hd {
    overflow: hidden;
    height: 30px;
    background: #f4f4f4;
    padding: 0 10px;
  }
  .picMarquee-left .prev,
  .picMarquee-left .next {
    position: absolute;
    top: 10px;
    right: 30px;
    display: block;
    width: 20px;
    height: 20px;
    margin-right: 5px;
    overflow: hidden;
    cursor: pointer;
    // background: url(../../assets/china/arrow_new.png) no-repeat;
  }
  .picMarquee-left .next {
    right: 8px;
    background-position: 0 -50px;
  }
  .prev {
    transform: rotate(-90deg);
  }
  .next {
    transform: rotate(90deg);
  }
  .picMarquee-left .prevStop {
    background-position: -60px 0;
  }
  .picMarquee-left .nextStop {
    background-position: -60px -50px;
  }
  .picMarquee-left .bd {
    padding: 10px;
  }
  .picMarquee-left .bd ul {
    overflow: hidden;
    zoom: 1;
  }
  .picMarquee-left .bd ul li {
    margin: 0 8px;
    float: left;
    _display: inline;
    overflow: hidden;
    text-align: center;
  }
  .picMarquee-left .bd ul li .pic {
    text-align: center;
  }
  .picMarquee-left .bd ul li .pic img {
    width: 220px;
    height: auto;
    display: block;
    padding: 2px;
    border: 1px solid #ccc;
  }
  .picMarquee-left .bd ul li .pic a:hover img {
    border-color: #999;
  }
  .picMarquee-left .bd ul li .title {
    line-height: 24px;
  }

  // @media screen and (max-width: 1920px) and (min-width: 1300px) {
  //   .bg {
  //     .china-section1-content--right {
  //       flex: 1;
  //       display: flex;
  //       justify-content: center;
  //       align-items: center;
  //       padding: 0 200px;
  //       img {
  //         min-width: 500px;
  //         /* max-width: 746px; */
  //         object-fit: contain;
  //       }
  //     }
  //   }
  // }
  // @media screen and (max-width: 1299px){
  //   .bg {
  //     .china-section1-content--right {
  //       flex: 1;
  //       display: flex;
  //       justify-content: center;
  //       align-items: center;
  //       padding: 0 138px;
  //       img {
  //         min-width: 500px;
  //         /* max-width: 746px; */
  //         object-fit: contain;
  //       }
  //     }
  //   }
  // }
}
</style>
