export const yearsConfig = [
  {
    year: 1983,
    content: [
      "INDIBA创始人Jose Calbet（何塞·卡贝特）先生发明了第一台高频电磁切割设备， 应用于医学手术，俗称“高频电刀”。",
      "INDIBA（英特波）研究中心于西班牙巴塞罗那注册成立。",
    ],
    img: require("../assets/home/img1.jpg"),
  },
  {
    year: 1985,
    content: [
      "Jose Calbet（何塞·卡贝特）先生发明生产了第一台拥有CET “电容性电移法 ”技术的肌肤再生仪 ，开创了世界温热疗法领导者的地位。",
    ],
    img: require("../assets/home/img9.jpg"),
  },
  {
    year: 1986,
    content: [
      "INDIBA（英特波）获得第十四届日内瓦国际发明展-金奖。",
      "INDIBA（英特波）获得布鲁塞尔国际发明展-银奖。",
    ],
    img: require("../assets/home/img10.png"),
  },
  {
    year: 1987,
    content: [
      "INDIBA（英特波）MD300系列肌肤再生仪取得国际专利、开始投入量产。",
      "INDIBA（英特波）获得马德里国际发明展 Salon Internacional de Innovación “INVENTALIA” - 金奖。",
    ],
    img: require("../assets/home/img2.jpg"),
  },
  {
    year: 1988,
    content: [
      "西班牙时任国王H.M King Juan Carlos接见INDIBA创始人Jose Calbet （何塞·卡贝特）并对其发明贡献表示赞赏及认可。",
    ],
    img: require("../assets/home/img3.jpg"),
  },
  {
    year: 1989,
    content: ["INDIBA MD301 / MD302系列上市"],
    img: require("../assets/home/img4.jpg"),
  },
  {
    year: 1991,
    content: [
      "INDIBA在仪器中引入RET“电阻式电移法”技术，并命名为TECAR （Transferencia Electrica capacitiva Resistiva ）。",
    ],
    img: require("../assets/home/img5.jpg"),
  },
  {
    year: 1994,
    content: ["INDIBA MD305 / MD306 系列上市。"],
    img: require("../assets/home/img5.png"),
  },
  {
    year: 1997,
    content: ["INDIBA（英特波）CET + RET技术 获得欧洲专利权"],
    img: require("../assets/home/img6.png"),
  },
  {
    year: 1998,
    content: [
      "INDIBA（英特波）与西班顶尖的 Ramony Cajal 医院签订了合约， 开始进行科研实验衡量448KHz对人体细胞起到的作用。",
    ],
    img: require("../assets/home/img11.jpg"),
  },
  {
    year: 2004,
    content: ["INDIBA（英特波）推出MD321 系列： CAP 300W – RES 100W。"],
    img: require("../assets/home/img7.png"),
  },
  {
    year: 2004,
    content: ["INDIBA（英特波）荣膺西班牙最优秀企业奖第一名。"],
    img: require("../assets/home/img8.png"),
  },
  {
    year: 2006,
    content: [
      "INDIBA（英特波）荣获西班牙王子奖，Asturias & Girona殿下为Jose Calbet（何塞·卡贝特）先生授奖。",
    ],
    img: require("../assets/home/img9.png"),
  },
  {
    year: 2007,
    content: [
      "INDIBA Deep Beauty ER 系列上市， INDIBA 448kHz科技从此将医美级的效果带进入了生活美容领域。",
      "INDIBA Human Tecar HCR系列上市。",
    ],
    img: require("../assets/home/img10.jpg"),
  },
  {
    year: 2008,
    content: ["INDIBA（英特波）亚洲公司正式于新加坡注册成立。"],
    img: require("../assets/home/img12.jpg"),
  },
  {
    year: 2011,
    content: ["INDIBA（英特波）专为物理治疗/运动康复而设的ACTIV系列正式上市。"],
    img: require("../assets/home/img6.jpg"),
  },
  {
    year: 2012,
    content: ["推出INDIBA DeepCare 医疗美容系列的INDIBA Elite和 Premium品牌。"],
    img: require("../assets/home/img7.jpg"),
  },
  {
    year: 2016,
    content: [
      "中国公司“英特波科技(北京)有限公司 ”正式在北京注册成立。",
      "INDIBA（英特波）全系列产品获得美国FDA认证。",
      "INDIBA（英特波）ACTIV AT 系列 与 INDIBA DEEP CARE NS 系列上市。",
    ],
    img: require("../assets/home/img12.png"),
  },
  {
    year: 2019,
    content: [
      "INDIBA（英特波）产品EDNA与EDNA1 PRO 获得欧洲产品设计金奖。",
      "英特波科技（深圳）有限公司注册成立。",
    ],
    img: null,
  },
  {
    year: 2020,
    content: [
      "INDIBA（英特波）产品EDNA与EDNA PRO 获Delta Selection 2020 设计铜奖。",
    ],
    img: "",
  },
  {
    year: 2021,
    content: ["INDIBA（英特波）DEEP BEAUTY产品C系列进入中国"],
    img: require("../assets/home/indiba_2021.png"),
  },
];

export const solutions = [];

export const address_footer = [
  {
    en_title: "BEIJING",
    zh_compony: "英特波科技(北京)有限公司",
    address1: "北京市朝阳区建国路93号院1号楼1808室",
    address2: "北京市朝阳区光华路4号东方梅地亚中心C座2801",
    city: "北京",
  },
  {
    en_title: "SHENZHEN",
    zh_compony: "英特波科技（深圳）有限公司",
    address1: "深圳市南山区粤海街道高新区社区高新南九道59号北科大厦709",
    city: "深圳",
  },
  {
    en_title: "SHANGHAI",
    zh_compony: "上海办公室",
    address1: "事业Ⅱ部：上海市长宁区古北路666号嘉麒大厦21楼03A室",
    address2: "事业Ⅰ部：上海市长宁区凯旋路1398号3号楼1005室（021-5299 8858",
    city: "上海",
  },
  {
    en_title: "CHENGDU",
    zh_compony: "成都办公室",
    address1: "成都市锦江区红星路三段1号国际金融中心IFS 1号办公楼30楼02号",
    city: "成都",
  },
];

{
  /* <h3>爱丽丝</h3>
<h4>Alice.pang</h4>
<h4>中国区职员</h4>
<h4>英特波科技(深圳)有限公司</h4> */
}

// 中国区
export const chinaStaff = [
  {
    name: "爱丽丝",
    enName: "Alice.pang",
    attributeproperty: "中国区职员",
    compony: "英特波科技(深圳)有限公司",
    imgId: "alice",
  },
  {
    name: "蔡苑薇",
    enName: "Vera.cai",
    attributeproperty: "中国区职员",
    compony: "英特波科技(深圳)有限公司",
    imgId: "caiyuanwei",
  },
  // {
  //   name: "陈蔚尔",
  //   enName: "Valerie Tan",
  //   attributeproperty: "中国区职员",
  //   compony: "英特波科技(深圳)有限公司",
  //   imgId: "chenweier",
  // },
  {
    name: "成小玉",
    enName: "Cassie.cheng",
    attributeproperty: "中国区职员",
    compony: "英特波科技(深圳)有限公司",
    imgId: "chengxiaoyu",
  },
  {
    name: "段丛梅",
    enName: "Summer.duan",
    attributeproperty: "中国区职员",
    compony: "英特波科技(深圳)有限公司",
    imgId: "duancongmei",
  },
  {
    name: "付晓耘",
    enName: "Kristen.fu",
    attributeproperty: "中国区职员",
    compony: "英特波科技(深圳)有限公司",
    imgId: "fuxiaoyun",
  },
  {
    name: "韩燕",
    enName: "vinnie.han",
    attributeproperty: "中国区职员",
    compony: "英特波科技(深圳)有限公司",
    imgId: "hanyan",
  },
  {
    name: "韩瑜璞",
    enName: "Linda.han",
    attributeproperty: "中国区职员",
    compony: "英特波科技(深圳)有限公司",
    imgId: "hanyupu",
  },
  {
    name: "郝芳芳",
    enName: "Teri.hao",
    attributeproperty: "中国区职员",
    compony: "英特波科技(深圳)有限公司",
    imgId: "haofangfang",
  },
  // {
  //   name: "何元",
  //   enName: "Yoyo.he",
  //   attributeproperty: "中国区职员",
  //   compony: "英特波科技(深圳)有限公司",
  //   imgId: "heyuan",
  // },
  {
    name: "侯薇",
    enName: "Vivian.hou",
    attributeproperty: "中国区职员",
    compony: "英特波科技(深圳)有限公司",
    imgId: "houwei",
  },
  {
    name: "胡庆松",
    enName: "hugh.hu",
    attributeproperty: "中国区职员",
    compony: "英特波科技(深圳)有限公司",
    imgId: "huqingsong",
  },
  {
    name: "胡晓英",
    enName: "Yuki.hu",
    attributeproperty: "中国区职员",
    compony: "英特波科技(深圳)有限公司",
    imgId: "huxiaoying",
  },
  // {
  //   name: "姜贺恩",
  //   enName: "Even.jiang",
  //   attributeproperty: "中国区职员",
  //   compony: "英特波科技(深圳)有限公司",
  //   imgId: "jiangheen",
  // },
  {
    name: "金春花",
    enName: "Flora.kim",
    attributeproperty: "中国区职员",
    compony: "英特波科技(深圳)有限公司",
    imgId: "jinchunhua",
  },
  {
    name: "酒月",
    enName: "Oifa.li",
    attributeproperty: "中国区职员",
    compony: "英特波科技(深圳)有限公司",
    imgId: "jiuyue",
  },
  {
    name: "李琛",
    enName: "wyman.li",
    attributeproperty: "中国区职员",
    compony: "英特波科技(深圳)有限公司",
    imgId: "lichen",
  },
  {
    name: "李宁",
    enName: "Jessica.li",
    attributeproperty: "中国区职员",
    compony: "英特波科技(深圳)有限公司",
    imgId: "lining",
  },
  // {
  //   name: "李雯婷",
  //   enName: "Paris.li",
  //   attributeproperty: "中国区职员",
  //   compony: "英特波科技(深圳)有限公司",
  //   imgId: "liwenting",
  // },
  // {
  //   name: "李洋",
  //   enName: "Sunny.li",
  //   attributeproperty: "中国区职员",
  //   compony: "英特波科技(深圳)有限公司",
  //   imgId: "liyang",
  // },
  {
    name: "李依燃",
    enName: "Irene.li",
    attributeproperty: "中国区职员",
    compony: "英特波科技(深圳)有限公司",
    imgId: "liyiran",
  },
  {
    name: "孟君阳",
    enName: "ivanka.meng",
    attributeproperty: "中国区职员",
    compony: "英特波科技(深圳)有限公司",
    imgId: "mengjunyang",
  },
  {
    name: "潘柏予",
    enName: "By.pan",
    attributeproperty: "中国区职员",
    compony: "英特波科技(深圳)有限公司",
    imgId: "panboyu",
  },
  {
    name: "沈卫",
    enName: "Jerry.shen",
    attributeproperty: "中国区职员",
    compony: "英特波科技(深圳)有限公司",
    imgId: "shenwei",
  },
  {
    name: "孙冰冰",
    enName: "Emily.sun",
    attributeproperty: "中国区职员",
    compony: "英特波科技(深圳)有限公司",
    imgId: "sunbingbing",
  },
  {
    name: "吴轶冰",
    enName: "Bingbing.wu",
    attributeproperty: "中国区职员",
    compony: "英特波科技(深圳)有限公司",
    imgId: "wuyibing",
  },
  // {
  //   name: "向妮娜",
  //   enName: "Nina.xiang",
  //   attributeproperty: "中国区职员",
  //   compony: "英特波科技(深圳)有限公司",
  //   imgId: "xiangnina",
  // },
  {
    name: "徐静",
    enName: "Amy.xu",
    attributeproperty: "中国区职员",
    compony: "英特波科技(深圳)有限公司",
    imgId: "xujing",
  },
  {
    name: "姚雨欣",
    enName: "Cynthia.yao",
    attributeproperty: "中国区职员",
    compony: "英特波科技(深圳)有限公司",
    imgId: "yaoyuxin",
  },
  {
    name: "叶菁菁",
    enName: "Sandy.ye",
    attributeproperty: "中国区职员",
    compony: "英特波科技(深圳)有限公司",
    imgId: "yejingjing",
  },
  // {
  //   name: "张德伦",
  //   enName: "Allen.zhang",
  //   attributeproperty: "中国区职员",
  //   compony: "英特波科技(深圳)有限公司",
  //   imgId: "zhangdelun",
  // },
  {
    name: "张立芳",
    enName: "Alisa.zhang",
    attributeproperty: "中国区职员",
    compony: "英特波科技(深圳)有限公司",
    imgId: "zhanglifang",
  },
  {
    name: "张文姬",
    enName: "maggie.zhang",
    attributeproperty: "中国区职员",
    compony: "英特波科技(深圳)有限公司",
    imgId: "zhangwenji",
  },
  {
    name: "张雪",
    enName: "Snow.zhang",
    attributeproperty: "中国区职员",
    compony: "英特波科技(深圳)有限公司",
    imgId: "zhangxue",
  },
  {
    name: "王宣琪",
    enName: "Niki.Wang",
    attributeproperty: "中国区职员",
    compony: "英特波科技(深圳)有限公司",
    imgId: "wangxuanqi",
  },
  {
    name: "宗雨霏",
    enName: "Faye.Zong",
    attributeproperty: "中国区职员",
    compony: "英特波科技(深圳)有限公司",
    imgId: "zongyufei",
  },
  {
    name: "巩昕",
    enName: "Kenny.Gong",
    attributeproperty: "中国区职员",
    compony: "英特波科技(深圳)有限公司",
    imgId: "gongxin",
  },
  // {
  //   name: "郑雯",
  //   enName: "Tracey.tan",
  //   attributeproperty: "中国区职员",
  //   compony: "英特波科技(深圳)有限公司",
  //   imgId: "zhenwen",
  // },
  {
    name: "卓岦颖",
    enName: "Livia.zhuo",
    attributeproperty: "中国区职员",
    compony: "英特波科技(深圳)有限公司",
    imgId: "zhuoliying",
  },
  {
    name: "敬一琳",
    enName: "Jenny.Jing",
    attributeproperty: "中国区职员",
    compony: "英特波科技(深圳)有限公司",
    imgId: "jingyilin",
  },
  {
    name: "赵怡倩",
    enName: "Jc.zhao",
    attributeproperty: "中国区职员",
    compony: "英特波科技(深圳)有限公司",
    imgId: "zhaoyiqian",
  },
  {
    name: "王兆佳",
    enName: "mila.wang",
    attributeproperty: "中国区职员",
    compony: "英特波科技(深圳)有限公司",
    imgId: "wangzhaojia",
  },
  {
    name: "杨锐敏",
    enName: "angeline.yang",
    attributeproperty: "中国区职员",
    compony: "英特波科技(深圳)有限公司",
    imgId: "yangruimian",
  },
];

export const fileUrl = "http://39.97.178.20";
